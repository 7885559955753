//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  head: {
    bodyAttrs: {
      class: 'font-sans',
    },
  },
};
