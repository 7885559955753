export const state = () => ({
  active: false,
  message: '',
  props: {},
});

export const actions = {
  show ({commit, dispatch}, mixed) {
    let message = '';
    let props = {timeout: 7500};
    let active = mixed.active || true;

    if (typeof mixed === 'object') {
      active = mixed.active !== false;
      message = mixed.message || message;
      props = {...mixed.props, ...props};
    } else if (typeof mixed === 'boolean') {
      active = mixed;
    } else if (typeof mixed === 'string') {
      message = mixed || message;
    }

    if (props.timeout !== 0) {
      setTimeout(() => dispatch('hide'), props.timeout);
    }

    commit('show', {message, props, active});
  },
  success ({dispatch}, message) {
    dispatch('show', {message, props: {color: 'primary'}});
  },
  info ({dispatch}, message) {
    dispatch('show', {message, props: {color: 'info'}});
  },
  error ({dispatch}, message) {
    dispatch('show', {message, props: {color: 'error'}});
  },
  hide ({commit}) {
    commit('show', {active: false});
  },
};

export const mutations = {
  show (state, {message, props, active}) {
    state.active = active !== false;
    state.message = message;
    state.props = {...(props || {})};
  },
};
