import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faArrowRight,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faBookOpen,
  faChild,
  faGlobeEurope,
  faMapMarkerAlt,
  faMinus,
  faPlus,
  faSearch,
  faSortDown,
  faSortUp,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faFacebookF,
  faLinkedinIn,
  faYoutube,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = false;

library.add(
  faArrowRight,
  faBookOpen,
  faChild,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faFacebookF,
  faGlobeEurope,
  faLinkedinIn,
  faMapMarkerAlt,
  faMinus,
  faPlus,
  faSearch,
  faSortDown,
  faSortUp,
  faTimes,
  faYoutube,
  faInstagram,
  faTwitter,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
