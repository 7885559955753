import {createHttpLink} from 'apollo-link-http';

export default function (app) {
  return {
    defaultHttpLink: false,
    inMemoryCacheOptions: {
      addTypename: false,
    },
    link: createHttpLink({
      uri: process.env.API_URL.replace(new RegExp('/api$'), '') + '/graphql',
      fetch: (uri, options) => {
        options.headers.authorization = app.$cookies.get('auth._token.local');

        return fetch(uri, options);
      },
    }),
  };
}
