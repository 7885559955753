import Vue from 'vue';
import GMap from "\u002Fdata\u002Fwww\u002Fportal.vervoerregio.nl\u002Fvva-autodeploy\u002Freleases\u002F20211223-130349_799dc3b3\u002Fverkeerskaart\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMap.vue";
import GMapMarker from "\u002Fdata\u002Fwww\u002Fportal.vervoerregio.nl\u002Fvva-autodeploy\u002Freleases\u002F20211223-130349_799dc3b3\u002Fverkeerskaart\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMapMarker.vue";
import GMapCircle from "\u002Fdata\u002Fwww\u002Fportal.vervoerregio.nl\u002Fvva-autodeploy\u002Freleases\u002F20211223-130349_799dc3b3\u002Fverkeerskaart\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMapCircle.vue";
import GMapInfoWindow from "\u002Fdata\u002Fwww\u002Fportal.vervoerregio.nl\u002Fvva-autodeploy\u002Freleases\u002F20211223-130349_799dc3b3\u002Fverkeerskaart\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMapInfoWindow.vue";
export default(context, inject) =>{
  Vue.component('GMap', GMap);
  Vue.component('GMapMarker', GMapMarker);
  Vue.component('GMapCircle', GMapCircle);
  Vue.component('GMapInfoWindow', GMapInfoWindow);
  inject('GMaps', {apiKey: "AIzaSyBAABTA6l3KhUymoticuaF63PJi1GmwgB8", loaded: false, libraries: undefined})
}