import {page as pageQuery} from '~/graphql/queries/page';
import {settings as settingsQuery} from '~/graphql/queries/settings';

export const state = () => ({
  release: process.env.RELEASE,
  settings: [],
  page: {seo: {}, template: {}},
  menuMain: {},
  menuFooter: {},
});

export const actions = {
  async nuxtServerInit ({dispatch}) {
    // await dispatch('storeDispatchFunc');
    await dispatch('getSettings');
  },

  async storeDispatchFunc ({commit}) {
    const [settings, menuMain, menuFooter] = await Promise.all([
      this.$axios.get('/settings'),
      this.$axios.get('/menu/main'),
      this.$axios.get('/menu/footer'),
    ]);

    commit('SET_SETTINGS', settings.data);
    commit('SET_MENU_MAIN', menuMain.data);
    commit('SET_MENU_FOOTER', menuFooter.data);
  },

  async getSettings ({commit}) {
    const {
      data: {settings},
    } = await this.app.apolloProvider.defaultClient.query({
      query: settingsQuery,
    });

    commit('SET_SETTINGS', settings);
  },

  async getPage ({commit}, slug = 'kaart') {
    const {
      data: {page},
    } = await this.app.apolloProvider.defaultClient.query({
      query: pageQuery,
      fetchPolicy: 'no-cache',
      variables: {
        slug: slug === '' ? 'kaart' : slug,
      },
    });

    if (!page) {
      const e = new Error();
      e.statusCode = 410;
      throw e;
    }

    commit('SET_PAGE', page);
  },
};

export const mutations = {
  SET_SETTINGS (state, payload) {
    state.settings = payload;
  },
  SET_PAGE (state, payload) {
    state.page = payload;
  },
  SET_MENU_MAIN (state, payload) {
    state.menuMain = payload;
  },
  SET_MENU_FOOTER (state, payload) {
    state.menuFooter = payload;
  },
};
